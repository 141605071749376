import React from "react";
import RenderMarkdown from "./utilities/RenderMarkdown";
import Section from "./Section";
import Video from "./Video.js";
import Cta from "./CTA";
import CountdownTimer from "./CountdownTimer";

export default function HeroSectionWithVideo({
  Container = "section",
  isSectionComponent = true,
  className = "",
  heroObject,
}) {
  // Passing Section component as string (like this: Container="Section") not possible somehow
  // either use isSectionComponent={true} or Container={Section} as property
  // isSection prop overrides Container prop!!
  if (isSectionComponent) {
    Container = Section;
  }

  const spaceBetweenChildrenClass = "space-y-5 sm:space-y-10";

  return (
    <Container
      className={`py-5 sm:py-10 sm:text-center ${
        !isSectionComponent && spaceBetweenChildrenClass
      } ${className}`}
      classNameInnerContainer={isSectionComponent && spaceBetweenChildrenClass}
    >
      <RenderMarkdown
        markdownContent={heroObject.kicker}
        className="text-center"
        whitespace="normal"
      />
      <RenderMarkdown
        markdownContent={heroObject.title}
        whitespace="normal"
        className="m-0 text-center space-y-3 sm:space-y-5"
      />

      <Video videoObject={heroObject.video} loading="eager" />
      {heroObject.subtitle && (
        <RenderMarkdown
        markdownContent={heroObject.subtitle}
        whitespace="normal"
        className="m-0"
        />
        )}
        {heroObject.date && (
          <CountdownTimer
            countdownTitle={"## Dein <b>50% Last-Call-Rabatt</b> läuft ab in:"}
            date={heroObject.date}
            classNameDigits={"font-semibold text-offbrand-red"}
          />
        )}

      {heroObject.ctaObject && <Cta ctaObject={heroObject.ctaObject} />}
    </Container>
  );
}
