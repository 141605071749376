import React from "react";
import { graphql } from "gatsby";
import HeaderWithLogin from "../components/HeaderWithLogin";
import Layout from "../components/Layout.js";
import HeroSectionWithVideo from "../components/HeroSectionWithVideo";
import Section from "../components/Section";
import BenefitsList from "../components/BenefitsList";
import ContentsList from "../components/ContentsList";
import BrandButton from "../components/BrandButton";
import { BackgroundImageDiv } from "../components/BackgroundImage";
import Steps from "../components/Steps.js";
import Overview from "../components/Overview.js";
import FlexGroup from "../components/utilities/FlexGroup";
import RenderMarkdown from "../components/utilities/RenderMarkdown.js";
import PreviewCompatibleImage from "../components/utilities/PreviewCompatibleImage.js";
import Introduction from "../components/Introduction.js";
import PricingMembership from "../components/PricingMembership.js";
import Video from "../components/Video.js";
import FAQs from "../components/FAQs";

const AaLandingPageTemplate = ({ data }) => {
  return (
    <>
      <HeaderWithLogin imageObject={data.headerObject.imageObject} />
      <Layout seoObject={data.seoObject}>
        <HeroSectionWithVideo
          className="bg-brand-beige-40"
          heroObject={data.heroObject}
        />
        <Section>
          <BenefitsList
            benefitsObject={data.benefitsObject}
            classNameIcon="text-brand-green"
            whitespaceList="normal"
          />
        </Section>
        <Section className="bg-brand-beige-40">
          <ContentsList
            contentsObject={data.contentsObject}
            classNameCard="bg-white"
          />
        </Section>
        <Section className="bg-brand-olive">
          <RenderMarkdown
            markdownContent={data.titleAboveContentsList2}
            whitespace="normal"
            className="text-white my-16"
          />
        </Section>
        <Section className="bg-white">
          <ContentsList
            contentsObject={data.contentsObject2}
            classNameCard="bg-white"
          />
        </Section>
        <Section className="bg-brand-beige-40">
          <RenderMarkdown markdownContent={data.textArea} />
        </Section>
        <BackgroundImageDiv
          imageObject={data.textWithBackgroundImage.imageObject}
          className="w-full h-full"
        >
          <Section className="">
            <div className="my-40">
              <article className="bg-brand-green bg-opacity-70 p-5 sm:p-8">
                <RenderMarkdown
                  markdownContent={data.textWithBackgroundImage.text}
                  className="text-white"
                />
              </article>
            </div>
          </Section>
        </BackgroundImageDiv>
        <Section className="bg-brand-mint">
          <RenderMarkdown
            markdownContent={data.coursesContentsTitle}
            className="text-center"
          />
          <ContentsList
            contentsObject={data.coursesContentsObject}
            classNameCard="bg-white"
            classNameText="text-left"
          />
        </Section>
        <Section className="bg-white">
          <Introduction introductionObject={data.introductionObject} />
        </Section>
        <Section className="bg-white">
          <ContentsList
            contentsObject={data.teachersContentsObject}
            classNameCard="bg-white"
          />
        </Section>
        <Section className="bg-brand-beige-40">
          <ContentsList
            contentsObject={data.mindfulnessContentsObject}
            classNameCard="bg-brand-beige-40"
          />
        </Section>
        <BackgroundImageDiv
          imageObject={data.textWithBackgroundImage2.imageObject}
          className="w-full h-full"
        >
          <Section className="">
            <div className="my-40">
              <article className="bg-brand-denim bg-opacity-70 p-5 sm:p-8">
                <RenderMarkdown
                  markdownContent={data.textWithBackgroundImage2.text}
                  className="text-white"
                />
              </article>
            </div>
          </Section>
        </BackgroundImageDiv>
        <Section className="bg-brand-olive">
          <RenderMarkdown
            markdownContent={data.titleAboveStepsObject}
            whitespace="pre-line"
            className="text-white text-center my-16"
          />
        </Section>
        <Section>
          <Steps stepsObject={data.stepsObject} />
        </Section>
        <BackgroundImageDiv
          imageObject={data.textWithBackgroundImage3.imageObject}
          className="w-full h-full"
        >
          <Section className="">
            <div className="my-40">
              <article className="bg-brand-green bg-opacity-70 p-5 sm:p-8">
                <RenderMarkdown
                  markdownContent={data.textWithBackgroundImage3.text}
                  className="text-white"
                />
              </article>
            </div>
          </Section>
        </BackgroundImageDiv>
        <Section>
          <Overview
            overviewObject={data.overviewObject}
            whitespace="pre-line"
          />
        </Section>
        <Section>
          <RenderMarkdown
            markdownContent={data.premiumObject.title}
            className="text-center"
          />
          {/* <FlexGroup
            ElementName={PreviewCompatibleImage}
            list={data.premiumObject.imageObjectList}
            classNameElement="w-5/12 my-10"
          /> */}
          <Video
            videoObject={data.premiumObject.video}
            className="mb-10 max-w-3xl mx-auto"
          />
          <RenderMarkdown
            markdownContent={data.premiumObject.description}
            className=""
          />
        </Section>
        <Section>
          <PricingMembership
            pricingMembershipObject={data.pricingMembershipObject}
          />
        </Section>
        <Section>
          <Overview
            overviewObject={data.refundPolicyObject}
            whitespace="pre-line"
          />
          <div className="flex justify-center">
            <PreviewCompatibleImage
              imageObject={data.refundPolicyObject.signatureImageObject}
              className="max-w-xs"
            />
          </div>
        </Section>
        <Section className="bg-brand-mint" classNameInnerContainer="space-y-5">
          <RenderMarkdown
            markdownContent={data.testimonialsObject.title}
            className="text-center"
          />
          <Video videoObject={data.testimonialsObject.video} />
          <div className="flex justify-center">
            <BrandButton
              href={data.testimonialsObject.ctaObject.linkUrl}
              className=""
            >
              <RenderMarkdown
                markdownContent={data.testimonialsObject.ctaObject.linkText}
                whitespace="normal"
              />
            </BrandButton>
          </div>
          {data.testimonialsObject.contentsObjectList.map((element, index) => (
            <ContentsList key={index} contentsObject={element} />
          ))}
        </Section>
        <Section>
          <FAQs faqsObject={data.faqsObject} />
        </Section>
        <BackgroundImageDiv
          imageObject={data.finalCtaObject.imageObject}
          className="w-full h-full"
        >
          <Section className="">
            <div className="my-40 space-y-10">
              <article className="justify-center p-5 sm:p-8">
                <RenderMarkdown
                  markdownContent={data.finalCtaObject.text}
                  className="text-center text-white"
                />
                <div className="flex justify-center">
                  <BrandButton
                    href={data.finalCtaObject.ctaObject.linkUrl}
                    color="bg-brand-mint"
                  >
                    <RenderMarkdown
                      markdownContent={data.finalCtaObject.ctaObject.linkText}
                      whitespace="normal"
                    />
                  </BrandButton>
                </div>
              </article>
            </div>
          </Section>
        </BackgroundImageDiv>
      </Layout>
    </>
  );
};

const AaLandingPage = ({ data }) => {
  return <AaLandingPageTemplate data={data.markdownRemark.frontmatter} />;
};

export default AaLandingPage;

export const pageQuery = graphql`
  query AaLandingPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        seoObject {
          slug
          domain
          description
          index
          title
          imageObject {
            alt
            image {
              childImageSharp {
                resize(width: 1200) {
                  src
                  height
                  width
                }
              }
            }
          }
        }
        headerObject {
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData(placeholder: NONE)
              }
            }
          }
        }
        heroObject {
          title
          subtitle
          video {
            cloudflareId
            hasGifThumbnail
          }
          ctaObject {
            linkText
            linkUrl
          }
        }
        benefitsObject {
          title
          list {
            description
            iconName
          }
          postscript
        }
        contentsObject {
          title
          list {
            imageObject {
              image {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
              alt
            }
            description
          }
        }
        titleAboveContentsList2
        contentsObject2 {
          ctaObject {
            linkText
            linkUrl
          }
          list {
            imageObject {
              image {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
              alt
            }
            description
          }
        }
        textArea
        textWithBackgroundImage {
          imageObject {
            image {
              childImageSharp {
                gatsbyImageData(placeholder: TRACED_SVG)
              }
            }
            alt
          }
          text
        }
        coursesContentsTitle
        coursesContentsObject {
          title
          ctaObject {
            linkText
            linkUrl
          }
          list {
            imageObject {
              image {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
              alt
              linkUrl
            }
            description
          }
        }
        introductionObject {
          imageObject {
            image {
              childImageSharp {
                gatsbyImageData(placeholder: TRACED_SVG)
              }
            }
            alt
          }
          imageSubtext
          introductionText
          signatureImageObject {
            image {
              childImageSharp {
                gatsbyImageData(placeholder: TRACED_SVG)
              }
            }
            alt
          }
        }
        teachersContentsObject {
          title
          list {
            imageObject {
              image {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
              alt
            }
            description
          }
        }
        mindfulnessContentsObject {
          title
          list {
            imageObject {
              image {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
              alt
            }
            description
          }
        }
        textWithBackgroundImage2 {
          imageObject {
            image {
              childImageSharp {
                gatsbyImageData(placeholder: TRACED_SVG)
              }
            }
            alt
          }
          text
        }
        titleAboveStepsObject
        stepsObject {
          steps {
            imageObject {
              image {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
              alt
            }
            description
            video {
              cloudflareId
              format
            }
          }
        }
        textWithBackgroundImage3 {
          imageObject {
            image {
              childImageSharp {
                gatsbyImageData(placeholder: TRACED_SVG)
              }
            }
            alt
          }
          text
        }
        overviewObject {
          title
          imageObject {
            image {
              childImageSharp {
                gatsbyImageData(placeholder: TRACED_SVG)
              }
            }
            alt
          }
          description
        }
        premiumObject {
          title
          video {
            cloudflareId
            hasGifThumbnail
          }
          imageObjectList {
            imageObject {
              image {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
              alt
            }
          }
          description
        }
        pricingMembershipObject {
          anchor
          title
          codeOffer
          titleOffer
          titleClosed
          regularPricePerMonth
          pricingCardObjectMonthly {
            title
            priceText
            priceDescription
            ctaObject {
              linkUrl
              linkText
            }
            priceOffer
            priceOfferText
            ctaTextClosed
          }
          pricingCardObjectYearly {
            overline
            title
            priceAdditive
            priceText
            priceDescription
            ctaObject {
              linkUrl
              linkText
            }
            priceOffer
            priceOfferText
            ctaTextClosed
          }
          reducedOfferObject {
            linkText
            linkUrl
          }
        }
        refundPolicyObject {
          title
          imageObject {
            image {
              childImageSharp {
                gatsbyImageData(placeholder: TRACED_SVG)
              }
            }
            alt
          }
          description
          signatureImageObject {
            image {
              childImageSharp {
                gatsbyImageData(placeholder: TRACED_SVG)
              }
            }
            alt
          }
        }
        testimonialsObject {
          title
          video {
            cloudflareId
          }
          ctaObject {
            linkUrl
            linkText
          }
          contentsObjectList {
            title
            list {
              imageObject {
                image {
                  childImageSharp {
                    gatsbyImageData(placeholder: TRACED_SVG)
                  }
                }
                alt
              }
              description
            }
            ctaObject {
              linkUrl
              linkText
            }
          }
        }
        faqsObject {
          title
          qAndA {
            question
            answer
          }
        }
        finalCtaObject {
          imageObject {
            image {
              childImageSharp {
                gatsbyImageData(placeholder: TRACED_SVG)
              }
            }
            alt
          }
          text
          ctaObject {
            linkText
            linkUrl
          }
        }
      }
    }
  }
`;
